import React from 'react';
import './App.css';
import { ContainersDisplay } from './ContainersDisplay';
import { Col, Row } from 'react-bootstrap';

export function RouteDisplay(props: any) {

  if (!props.data.serviceDate) {
    return (<div style={{ width: '100%', height: '100vh', background: 'lightgray', fontSize: '2em', fontWeight: 'Bold'}}>{props.children}</div>)
  }

  return (
    <div
    style={{ marginLeft: '1em', width: '100%', height: '100%'}}>
    <Row
      style={{marginBottom: '3em', fontSize: '1.8em', fontWeight: 'Bold'}}>
      <Col>{props.data.serviceDate.toISOString().slice(0, 10)}</Col>
      <Col>Route driver report</Col>
      <Col></Col>
      </Row>
    <ContainersDisplay
    style={{ width: '50em', fontSize: '1.2em'}}
      containers={props.data.totals}/>
    {
      props.data.routes.map((route: any) => {

        return (
          <div key={route.route}>
          <div className="pagebreak"> </div>
          <Row
      style={{marginBottom: '1.2em', fontSize: '1.8em', fontWeight: 'Bold'}}>
      <Col>{props.data.serviceDate.toISOString().slice(0, 10)}</Col>
      <Col>Route { route.route }</Col>
      <Col></Col>
      </Row>
        <ContainersDisplay
          style={{fontSize: '1em', width: '30em', marginBottom: '2em'}}
          containers={route.containers}/>
          <div
          style={{fontSize: '1.2em', fontWeight: 'bold', marginBottom: '1em'}}>
            Locations</div>
          {
            route.locations.map((location: any) => {
              return (
                <div
                key={location.locationCode}
                className="location"
                style={{width: '100%'}}>
                  <Row key={location.locationCode}>
                    <Col sm="5">
                    <div
                    style={{fontWeight: 'Bold'}}
                  >{location.customerName}</div>
                  <div
                    style={{fontWeight: 'Bold'}}
                  >{location.customerId}</div>
                  <div>{location.address}</div>
                  <div>{location.city}, {location.state} {location.zip}</div>
                  </Col>
                  <Col sm="7">
                  <ContainersDisplay
                    style={{fontSize: '1em'}}
                  containers={location.containers}/>
                  </Col>
                  </Row>
                  <hr/>
                </div>
              )
            })
          }
          </div>
          )
      }
      )
    }
    </div>
  );

}
// React.Fragment
// export default RouteDisplay;
