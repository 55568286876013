import React, { useState } from 'react';
import './App.css';
import { Drop } from './Drop';
import { Col, Row, Button } from 'react-bootstrap';

function App() {

  const [printLabel, setPrintLabel] = useState(false);

  return (
    <React.Fragment>
        <Button id="noPrintButton" onClick={() => setPrintLabel(!printLabel)}>Set to {printLabel ? 'Route Report' : 'Bin Labels'}</Button>
    <Row>
      <Col>
        <Drop printLabel={printLabel}>Drop files for {printLabel ? 'Labels Report' : 'Driver Report'}</Drop>
      </Col>
    </Row>
    </React.Fragment>
  );
}

export default App;
