import React from 'react';
import './App.css';
import { Col, Row } from 'react-bootstrap';
import Barcode from 'react-barcode';

export function LabelDisplay(props: any) {

  if (!props.labels.length) {
    return (<div style={{ width: '100%', height: '100vh', background: 'lightgray', fontSize: '2em', fontWeight: 'Bold'}}>{props.children}</div>)
  }

  return (
    <div
    style={{ marginLeft: '1em', width: '100%', height: '100%'}}>
    <Row
      style={{marginBottom: '3em', fontSize: '1.8em', fontWeight: 'Bold'}}>
      <Col>{
      // props.data.serviceDate.toISOString().slice(0, 10)
    }</Col>
      <Col>Route Labels</Col>
      <Col></Col>
      </Row>
    <Row>
      {
        props.labels.map((label: any) => {
          return (
            <div className="keeptogether" style={{marginBottom: '18px'}}>
              <Row><Col><b>Route: {label.route}</b></Col><Col><b>{label.containerType}</b></Col></Row>
              <Row><Col><b>{label.customerName}</b></Col></Row>
              <Row><Col><b>{label.customerId}</b></Col><Col><Barcode height={ 30 } value={label.profileId}/></Col></Row>
              <Row><Col><b>{label.address}</b></Col></Row>
              <Row><Col>{label.city}, {label.state}</Col></Row>
              <Row><Col>{label.containerIndex} of {label.containerTotal}</Col></Row>
            </div>
          )
        })
      }
    </Row>
    </div>
  );
}
// React.Fragment
// export default RouteDisplay;
