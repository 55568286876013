import React from 'react';
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';

export function ContainersDisplay(props: any) {

  if (!props.containers) {
    return (<div></div>)
  }
  props.containers.sort((a: any, b: any) => {

    return a.containerType.toString() > b.containerType.toString() ? 1 : -1;
  });

  return (
    <Container
    style={props.style}>
      <Row
      style={{ fontWeight: 'bold'}}>
      <Col></Col>
      <Col>With Mount</Col>
      <Col>Mount and Sensor</Col>
      <Col>Standard</Col>
      <Col>Total</Col>
        </Row>
    {
      props.containers.map((container: any) => {
        return (
          <Row key={container.containerType}>
                <Col
                style={{ fontWeight: 'bold'}}
                >{ container.containerType }</Col>
          <Col>{ container.quantitySensorAt }</Col>
          <Col>{ container.quantitySensorReady }</Col>
          <Col>{ container.quantityNormal + container.quantitySensorNeed}</Col>
          <Col>{ container.quantitySensorAt + container.quantityNormal + container.quantitySensorReady + container.quantitySensorNeed }</Col>
          </Row>
          )
      }
      )
    }
    </Container>
  );
}
