import React, { useState, useCallback }  from 'react';
import { processFile } from './processXlsx';
import { RouteDisplay } from './RouteDisplay';
import { LabelDisplay } from './LabelDisplay';

export function Drop(props: any) {
  const drop:any = React.useRef(null);

  const [data, setData] = useState({});
  const [labels, setLabels] = useState({});

  const handleDragOver = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
    },
    [],
  );

  const handleDrop = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const {files} = e.dataTransfer;
      
      if (files && (files.length === 2)) {
        const res = await processFile(files);

          const labels = [];
          const labelsJson: any[] = [];
          let cnt = 0;
          labels.push('"Route","Customer","CustomerID","Address","City","State","Container","Index","Total"');
          res.routes.forEach((route: any) => {
            route.locations.forEach((location: any) => {
              const containers = location.containers.filter((row: any) => {
                return !row.containerType.startsWith || (!row.containerType.startsWith('C'));
              });
              containers.forEach((container: any) => {
                for(let c = 0; c < container.quantitySensorNeed; c++) {
                  const label = `"${route.route}","${location.customerName}","${location.customerId}","${location.address}","${location.city}","${location.state}","${container.containerType}","${c + 1}","${container.quantitySensorNeed}"`;
                  labels.push(label);
                  labelsJson.push({
                    cnt: cnt,
                    route: route.route,
                    customerName: location.customerName,
                    customerId: location.customerId,
                    profileId: location.profileId,
                    address: location.address,
                    city: location.city,
                    state: location.state,
                    containerType: location.containerType,
                    containerIndex: c + 1,
                    containerTotal: container.quantitySensorNeed,
                  });
                }
              });
            });
        });
        setLabels(labelsJson);
        setData(res);
      /*
        const txt = labels.join('\n');
        console.log(txt);
        console.log('lj', labelsJson);
        */
      }
    },
    [],
  );

  return (
    <div>
    <div ref={drop}
    onDragOver={handleDragOver}
    onDrop={handleDrop}
    style={{ minWidth: '10rem', minHeight: '10rem'}}>
      {props.printLabel
        ? <LabelDisplay labels={labels}>{props.children}</LabelDisplay>
        : <RouteDisplay data={data}>{props.children}</RouteDisplay>
      }
      
    </div>
    </div>
  )
}
