const reader = require('xlsx');

enum LocationSensorType {
  SensorNone,
  SensorAt,
  SensorReady,
  SensorNeed,
}

// locationHasSensor
// 
function getProfileId(sensorLocations: any[], value: any) : String {

  const sensorLocation = sensorLocations.find((row: any) => {

    // console.log(row['Cust ID'], location['Customer Id'], row['Addr1'], location['Address'], row['City'], location['City']);

    return (value['Customer Id']) &&
    (row['Cust ID'] === value['Customer Id']) &&
    (row['Addr1'] === value.Address) &&
    (row['City'] === value.City);
  });

  if (!sensorLocation) {
    return 'none'
  }
 // console.log('a', sensorLocation['Profile ID'], sensorLocation)
  return sensorLocation['Profile ID']
}

function getLocationSensorType(sensorLocations: any[], location: any) : LocationSensorType {

  const sensorLocation = sensorLocations.find((row: any) => {

    // console.log(row['Cust ID'], location['Customer Id'], row['Addr1'], location['Address'], row['City'], location['City']);

    return (row['Cust ID'] === location.customerId) &&
    (row['Addr1'] === location.address) &&
    (row['City'] === location.city);
  });
  

  if (sensorLocation) {
    
    const atCustDateRaw = sensorLocation['AT CUST DATE']
    
    if (atCustDateRaw) {
      // To handle timezone issues, putting one day in future
      const atCustDate = new Date(Date.UTC(0, 0, atCustDateRaw - 1 + 1))
      const now = new Date()
      // const today = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, now.getDate()))
      // console.log('AT CUST DATE', now.getTime() > atCustDate.getTime(), now, atCustDate, sensorLocation)

      if (now.getTime() > atCustDate.getTime()) {
        return LocationSensorType.SensorAt
      } else {
        return LocationSensorType.SensorReady
      }
    } else {
      return LocationSensorType.SensorNeed
    }
  } else {
    return LocationSensorType.SensorNone
  }
}

export async function processFile(filesIn: any[]) {

  let data: any = [];
  let sensorLocations: any = [];
    
  for (let f = 0; f < filesIn.length; f++) {
    const fileData = await filesIn[f].arrayBuffer();
    const file = reader.read(fileData);
    const sheets = file.SheetNames;
      
    for(let i = 0; i < sheets.length; i++)
    {
      const temp = reader.utils.sheet_to_json(
            file.Sheets[file.SheetNames[i]]);
      temp.forEach((res: any) => {
          if (res['Service Date']) {
            if (res['Order Type'] === 'D') {
              data.push(res);
            }
          } else {
//            if (file.SheetNames[i] === 'LATEST_INSTALL_STATUS') {
              if (i === 0) {
                // console.log('res', res)
              sensorLocations.push(res);
            }
          }
      });
    }
  }

   // console.log('d', data);
   // console.log('l', sensorLocations);

  // Printing data
  const result: any[] = [];
  const totals: any[] = [];
  let serviceDate: any;

  if (data.length > 0) {
    serviceDate = new Date(Date.UTC(0, 0, data[0]['Service Date'] - 1))
  }
  data.reduce((res: any, value: any) => {

    const profileId = getProfileId (sensorLocations, value)
  
    if (!res[value.Route]) {
      res[value.Route] = { route: value.Route, locations: [], containers: [] };
      result.push(res[value.Route]);
    }

    const route = res[value.Route];
    const locationCode = value['Customer Id'] + '~' + value['Ship To Code'];

    if (!route.locations.find((row: any) => row.locationCode === locationCode)) {
      route.locations.push({
        locationCode: locationCode,
        address: value.Address,
        customerName: value['Customer Name'],
        customerId: value['Customer Id'],
        profileId: profileId,
        atCustomerDate: value['At Cust Date'],
        city: value.City,
        state: value.State,
        zip: value.Zip,
        containers: [],
      });
    }

    const location = route.locations.find((row: any) => row.locationCode === locationCode);

    if (!location.containers.find((row: any) => row.containerType === value['Container Type'] )) {
      location.containers.push({
        containerType: value['Container Type'],
        quantitySensorAt: 0,
        quantitySensorReady: 0,
        quantitySensorNeed: 0,
        quantityNormal: 0,
      });
    }

    const locationContainer = location.containers.find((row: any) => row.containerType === value['Container Type'] );
    
    const locationType = getLocationSensorType(sensorLocations, location)
    if (locationType === LocationSensorType.SensorAt) {
      locationContainer.quantitySensorAt += value.Quantity;
    } else if (locationType === LocationSensorType.SensorReady) {
      locationContainer.quantitySensorReady += value.Quantity;
    } else if (locationType === LocationSensorType.SensorNeed) {
      locationContainer.quantitySensorNeed += value.Quantity;
    } else {
      locationContainer.quantityNormal += value.Quantity;
    }

    if (!route.containers.find((row: any) => row.containerType === value['Container Type'] )) {
      route.containers.push({
        containerType: value['Container Type'],
        quantitySensorAt: 0,
        quantitySensorReady: 0,
        quantitySensorNeed: 0,
        quantityNormal: 0,
      });
    }

    const routeContainer = route.containers.find((row: any) => row.containerType === value['Container Type'] );

    if (locationType === LocationSensorType.SensorAt) {
      routeContainer.quantitySensorAt += value.Quantity;
    } else if (locationType === LocationSensorType.SensorReady) {
      routeContainer.quantitySensorReady += value.Quantity;
    } else if (locationType === LocationSensorType.SensorNeed) {
      routeContainer.quantitySensorNeed += value.Quantity;
    } else {
      routeContainer.quantityNormal += value.Quantity;
    }

    if (!totals.find((row: any) => row.containerType === value['Container Type'] )) {
      totals.push({
        containerType: value['Container Type'],
        quantitySensorAt: 0,
        quantitySensorReady: 0,
        quantitySensorNeed: 0,
        quantityNormal: 0,
      });
    }

    const container = totals.find((row: any) => row.containerType === value['Container Type'] );
    // console.log('c', totals, value['Container Type']);
    if (locationType === LocationSensorType.SensorAt) {
      container.quantitySensorAt += value.Quantity;
    } else if (locationType === LocationSensorType.SensorReady) {
      container.quantitySensorReady += value.Quantity;
    } else if (locationType === LocationSensorType.SensorNeed) {
      container.quantitySensorNeed += value.Quantity;
    } else {
      container.quantityNormal += value.Quantity;
    }

    return res;
  });
  result.forEach((row: any) => {
    row.locations.sort((a: any, b: any) => a.customerName > b.customerName ? 1 : -1);
  });
  result.sort((a: any, b: any) => a.route > b.route ? 1 : -1);
  console.log('routes', result);
  return {totals: totals, routes: result, serviceDate: serviceDate};
}
/*
 Route: 'SR',
    'Service Date': 44917,
    'Customer Id': 'WMUSS',
    'Customer Name': 'MORGAN STANLEY WEALTH MGMT US SHRED',
    'Department Id': 'G484',
    'Ship To Code': 'GW484',
    Address: '23 WATER ST',
    City: 'BANGOR',
    State: 'ME',
    Zip: '04401-6364',
    'Order Type': 'D',
    'Container Type': '65-GAL',
    Quantity: 1,
    'Address Contact': 'PAULA MCCAFFERTY',
    'Address Phone Number': '(207)561-2031',
    'Visit Profile Contact': 'PAULA MCCAFFERTY',
    'Visit Profile Phone Number': '(207)561-2031',
    Cycle: 'B TWICE EVERY MONTH'
      */
     